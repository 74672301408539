import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons'
import 'element-plus/theme-chalk/index.css'
import './assets/scss/appCss.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import dayjs from 'dayjs'
import btnPerms from "@/utils/permission";
const app = createApp(App)
for (const name in ElIcons){app.component(name,(ElIcons as any)[name])
}
btnPerms(app)
app.use(store)
app.use(router)
app.use(ElementPlus,{ locale})
app.mount("#app")
app.config.globalProperties.dayjs=dayjs//全局挂载