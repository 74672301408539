/*
 * @Author: taochunhui 814995688@qq.com
 * @Date: 2022-08-24 09:56:24
 * @LastEditors: taochunhui 814995688@qq.com
 * @LastEditTime: 2022-08-24 10:08:14
 * @FilePath: /vue3-ts-demo/src/request/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
//创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000,
    headers: {
        'content-Type': 'application/json;charset=utf-8'
    }
})
//请求拦截
service.interceptors.request.use((config)=>{
    config.headers = config.headers || {}
    if(localStorage.getItem('token')){
        config.headers.Authorization = localStorage.getItem('token') || ''
    }
    return config
})
//响应拦截
service.interceptors.response.use((res)=>{
    return res
},(err)=>{
    if(err.response.status == 403){
          ElMessage.error(err.response.data.message)
    }
    if(err.response.status == 401){
        ElMessage.error('登录过期')
        var storage = window.localStorage;
        storage.clear()
        router.push('/home')
    }
    if(err.response.status == 500){
          ElMessage.error(err.response.data.message)
    }
    return Promise.reject(err)
})

export default service