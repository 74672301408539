export default function btnPerms(app) {
  app.directive('has', {
      mounted(el, binding) {
          if (!permsJudge(binding.value)) {
              el.parentNode.removeChild(el);
          }
          function permsJudge(value){
            const permsArr: any = localStorage.getItem('perms')
            const newArr = permsArr.split(',')
            for (let item of newArr) {
                if (item === value) {
                    return true;
                }
            }
            return false;
          }
      }
  });
}
