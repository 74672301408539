import service  from "./index";
import axios from 'axios'
// const servicelogin = axios.create({baseURL: 'http://81.70.241.19:8021',timeout: 5000,headers: {'content-Type': 'application/json;charset=utf-8','Authorization':'Basic ZHMtcGxhdGZvcm06YWJjMTAyNA=='}})
// //响应拦截
// servicelogin.interceptors.response.use((res)=>{return res.data},(err)=>{console.log(err)})
//退出登录接口 
export const authRevoke = (): any => service.post(`auth/revoke?token=` + localStorage.getItem('token'))
//获取用户当前的权限  
export const userGetAuth = (): any => service.get("app/role/getAuth?appId=d6b326626004")  
//登录接口获取token
export const loginAPI = (params: string): any => service.post(`/auth/token?code=${params}&redirectUri=${process.env.VUE_APP_BASE_APP}/auth/callback&code_verifier=` + localStorage.getItem('code_challenge'))
//城市区县接口 
export const areaCode = (params:string): any => service.get("app/assist/permit/area",{params})
//字典相关接口
export const searchListAPI = (params:string): any => service.get("app/dictionary/search",{params})
export const modifyStatus = (params: string): any => service.get(`app/dictionary/modifyStatus/${params}?type=0`)
export const modifyDel = (params: string): any => service.get(`app/dictionary/modifyStatus/${params}?type=1`)
export const modifyAdd = (data: object): any => service.post(`app/dictionary/add`,data)
//权限相关接口
export const authorityAPI = (params:string): any => service.get("app/authority/treeSysAuthority",{params})   
export const authorityDel = (params: string): any => service.get(`app/authority/del/${params}`)
export const authorityAdd = (data: object): any => service.post(`app/authority/add`,data)
//角色相关接口
export const roleAPI = (params:string): any => service.get("app/role/search",{params})
export const roleAdd = (data: object): any => service.post(`app/role/add`,data)
export const roleDel = (params: string): any => service.get(`app/role/del/${params}`)
export const roleAuthAdd = (data: object): any => service.post(`app/role/roleAuthAdd`,data)
export const roleDataAdd = (data: object): any => service.post(`app/dataAuthority/add`,data)
export const roleAuthDetail = (params: string): any => service.get(`app/role/roleAuthDetail/${params}`)
export const dataAuthority = (params: string): any => service.get(`app/dataAuthority/${params}`)
export const treeOrgListAPI = (): any => service.get("admin/org/treeOrgList") 
//机构相关接口
export const orgList = (params:string): any => service.get("admin/org/search",{params}) 
export const orgAdd = (data: object): any => service.post(`admin/org/create`,data)
export const orgApproval = (data: object): any => service.post(`admin/org/approval`,data)
export const orgDel = (params: string): any => service.get(`admin/org/del/${params}`)
//用户相关接口  
export const userList = (params:string): any => service.get("app/user/search",{params})  
export const userStatus = (params: string): any => service.get(`app/user/status/${params}?type=2`)
export const userDel = (params: string): any => service.get(`app/user/status/${params}?type=1`)  
export const userChong = (params: string): any => service.get(`app/user/resetPassword/${params}`)  
export const userAdd = (data: object): any => service.post(`app/user/edit`,data)  
export const userRoleAdd = (data: object): any => service.post(`app/role/userRoleAdd`,data)
export const getRoleByUserId = (params: string): any => service.get(`app/role/getRoleByUserId/${params}`)
//应用相关接口  
export const applicationList = (params:string): any => service.get("admin/app/search",{params})   
export const applicationAdd = (data: object): any => service.post(`admin/app/create`,data)  
export const applicationApproval = (data: object): any => service.post(`admin/app/approval`,data) 
export const applicationStatus = (params: string): any => service.get(`admin/app/editStatus/${params}?type=2`)
export const applicationDel = (params: string): any => service.get(`admin/app/editStatus/${params}?type=1`)  
//用户权限  
export const userRole = (): any => service.get("app/role/userRole") 
export const userAppRole = (): any => service.get("app/authority/userAuthApp") 