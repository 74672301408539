import { createRouter, createWebHistory } from 'vue-router'
import { userGetAuth } from "@/request/api"
import Layout from '../layout/HomeView.vue'
const routes = [
    {
        path: "/index",
        name: "index",
        meta: {
            title: "首页",
            icon: "HomeFilled",
            iconClass: "HomeFilled",
            hidden: true,
        },
        component: Layout,
        children:[
            {
                path: "/index",
                name: "index",
                meta: {title: "首页",hidden: true,},
                component: () => import("@/views/index/index.vue"),
            }
        ]
    },
    {
        path: "/home",
        name: "home",
        meta: {hidden: true,},
        component: () => import("@/views/home/home.vue"),
    },
    {
        path: '/denglu',
        name: 'denglu',
        meta: {hidden: true,},
        component: () => import('@/views/denglu.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {hidden: true,},
        component: () => import('@/views/logout.vue')
    },
    {
        path: '/auth/callback',
        name: 'callback',
        meta: {hidden: true,},
        component: () => import('@/views/callback.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {hidden: true,},
        component: () => import('@/views/404.vue')
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})
let registerRouteFresh = true;
router.beforeEach(async(to, from, next) => {
    const token: string | null = localStorage.getItem('token')
    if(token){
        if(registerRouteFresh){
            let res = await userGetAuth()
            let navigationList = res.data
            const newArr = new Array()
            const permsArr = new Array()
            navigationList.forEach(item => {
                const obj : any = new Object()
                const children = new Array()
                obj.path = `/${item.route}`
                obj.name = `${item.route}`
                obj.component = Layout
                if(item.children.length != 0) {
                    obj.meta = {title: item.name,iconClass: `${item.img}`}
                    item.children.forEach(i => {
                        if(item.id == i.parentId) {
                            children.push({
                                path: `/${i.route}`,
                                name: `${i.route}`,
                                meta: { title: i.name,iconClass:`${item.img}`,hidden: true},
                                component:() => import(`@/views/${item.route}/${i.route}.vue`)
                            })
                        }
                        i.children.forEach(v => {
                            permsArr.push(v.expression)
                        })
                    })
                }else{
                    obj.meta = {title: item.name,iconClass:`${item.img}`,hidden: true}
                    children.push({
                        path: `/${item.route}`,
                        name: `${item.route}`,
                        meta: { title: item.name,iconClass:`${item.img}`,hidden: true},
                        component:() => import(`@/views/${item.route}/${item.route}.vue`)
                    })
                }
                obj.children = children
                newArr.push(obj)
            })
            localStorage.setItem('perms', permsArr.toString())
            newArr.forEach(i =>{
                router.addRoute(i)
            })
            next({ ...to, replace: true });
            registerRouteFresh = false;
        }else{
            next();
        }
    }else{
        if(to.path !== '/denglu' && to.path !== '/auth/callback'){
            next('/denglu')
        }else {
            next()
        }
    }
})

export default router



