
    import {defineComponent,ref,computed} from 'vue'
    import { useRouter, useRoute} from 'vue-router';
    import  CommonHeader  from "@/layout/components/Header.vue";
    export default defineComponent({
        components: {CommonHeader},
        setup() {
            const isCollapse = ref(false)
            const router = useRouter()
            const routers = router.getRoutes()
            const toggleClick = ()=> {
                isCollapse.value = !isCollapse.value
            }
            return {
                isCollapse,
                toggleClick,
                routers
            }
        }
    })
